import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

const opts = {
  iconfont: "fa4",
  customProperties: true,
  variations: false,
  themeCache: {
    get: (key) => localStorage.getItem(key),
    set: (key, value) => localStorage.setItem(key, value),
  },
};

const vuetify = new Vuetify({
  theme: {
    options: { opts: opts, customProperties: true },
    dark: false,
    themes: {
      light: {
        primary: "#101010",
        secondary: "#0e0e0e",
        tertiary: "#838097",
        accent: "#fb8c00",
        accentContrast: "#151515",
        gold: "#fdd164",

        itemAddedToCartBadge: "#07aa07",
        cart: "#07aa07",

        bodyText: "#181818",
        bodyBackground: "#f1f1f1",
        sectionBackground: "#fff",

        footer: "#142b51",
        footerText: "#e4e9f0",
        accentFooter: "#e4e9f0",
        accentFooterText: "#142b51",

        button: "#cb3049",
        buttonText: "#fff",

        sidebarBackground: "#e5e5e5",

        header: "#e4e9f0",
        headerText: "#142b51",
        subHeader: "#cb3049",
        subHeaderText: "#ffffff",

        searchBar: "#fff",
        subMenuBackground: "#fff",
        productOffer: "#07aa07",
      },
      dark: {
        primary: "#373737",
        secondary: "#2F2F2F",
        tertiary: "#434343",
        accent: "#fb8c00",
        accentContrast: "#151515",

        itemAddedToCartBadge: "#07aa07",
        cart: "#07aa07",

        bodyText: "#d9d9d9",
        bodyBackground: "#181818",
        sectionBackground: "#1e1e1e",

        footer: "#040404",
        footerText: "#d9d9d9",
        accentFooter: "#1e1e1e",
        accentFooterText: "#d9d9d9",

        button: "#1e1e1e",
        buttonText: "#142b51",

        sidebarBackground: "#1e1e1e",

        header: "#e4e9f0",
        headerText: "#1e1e1e",
        subHeader: "#cb3049",
        subHeaderText: "#ffffff",

        searchBar: "#979da5",
        subMenuBackground: "#1f1f1f",
        productOffer: "#07aa07",
      },
    },
  },
});
export default vuetify;

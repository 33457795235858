import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import toast from "./plugins/toast";
import '../public/style.css'
import Vuetify from "vuetify/lib";
import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader
Vue.config.productionTip = false;
Vue.prototype.$eventHub = new Vue(); // Global event bus


const tmpVueTify = new Vuetify({
  theme: {
    breakpoint: vuetify.preset.breakpoint,
    icons: vuetify.preset.icons,
    lang: vuetify.preset.lang,
    themes: vuetify.preset.theme.themes,
  },
});

new Vue({
  router,
  iconfont: "mdi",
  vuetify: tmpVueTify,
  toast,
  render: (h) => h(App),
}).$mount("#app");

<template>
  <div>
    <div>
      <v-navigation-drawer v-model="drawer" absolute temporary>
        <div class="d-flex justify-end">
          <v-btn icon @click="drawer = !drawer" class="pr-4">
            <v-icon large color="red">mdi-chevron-double-left</v-icon>
          </v-btn>
        </div>
        <v-list
          nav
          dense
          class="d-flex align-center justify-center"
          height="250px"
        >
          <v-list-item-group active-class="blue--text text--accent-4">
            <v-list-item v-for="(route, index) in routerOptions" :key="index">
              <v-list-item-title
                ><router-link
                  :to="route.to"
                  class="text-subtitle-1 text-decoration-none font-weight-bold"
                  >{{ route.title }}</router-link
                >
              </v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
      <v-toolbar
        class="py-0"
        elevation="0"
        height="50"
        :color="'#E0E0E0'"
        v-if="$vuetify.breakpoint.mdAndUp"
      >
        <div
          style="width: 100%"
          class="d-flex align-center"
          :class="$vuetify.breakpoint.lgAndUp ? 'boxed' : 'container'"
        >
          <v-toolbar-items>
            <span class="black--text font-weight-bold">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" @click="openMail" v-on="on" left>
                    mdi-email
                  </v-icon>
                </template>
                <span>Entrar em contato</span>
              </v-tooltip>

              mentor@alexbazzo.com.br &nbsp;|
              <v-icon left class="pl-2">mdi-phone</v-icon> (45) 9995514501</span
            >
          </v-toolbar-items>
          <v-spacer></v-spacer>
          <v-toolbar-items
            v-for="(item, index) in socialMediaOptions"
            :key="index"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  :color="item.color"
                  icon
                  class="mx-1"
                  @click="redirectToSocialMedia(item.to)"
                >
                  <v-icon>{{ item.icon }}</v-icon>
                </v-btn>
              </template>
              <span>{{ item.title }}</span>
            </v-tooltip>
          </v-toolbar-items>
        </div>
      </v-toolbar>
      <v-app-bar
        :elevation="0"
        :color="'#f1f0f0'"
        :height="$vuetify.breakpoint.smAndDown ? '120px' : '120px'"
      >
        <div
          style="width: 100%"
          :class="$vuetify.breakpoint.mdAndUp ? 'boxed' : 'container'"
        >
          <v-row class="d-flex justify-space-around">
            <v-col
              md="4"
              lg="3"
              cols="6"
              class="ml-md-n5 ml-lg-n10 ml-xl-n16 mt-xl-2 mt-md-0 mt-5 ml-n3"
            >
              <v-toolbar-title>
                <img
                  src="../assets/logo.png"
                  class="ml-xl-n12 ml-lg-n10"
                  :width="logoImageWidth"
                />
              </v-toolbar-title>
            </v-col>
            <v-spacer></v-spacer>
            <v-col
              md="8"
              lg="9"
              cols="11"
              class="d-flex align-center justify-end"
              v-if="$vuetify.breakpoint.mdAndUp"
            >
              <v-toolbar-items class="d-flex justify-space-around">
                <router-link
                  v-for="(route, index) in routerOptions"
                  :key="index"
                  class="mx-3 text-design font-weight-bold text-h6"
                  :to="route.to"
                  >{{ route.title }}</router-link
                >
              </v-toolbar-items>
            </v-col>
            <v-col class="d-flex justify-end align-center" v-else>
              <v-app-bar-nav-icon
                @click.stop="drawer = !drawer"
              ></v-app-bar-nav-icon>
            </v-col>
          </v-row>
        </div>
      </v-app-bar>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    logoImageWidth() {
      if (this.$vuetify.breakpoint.lg) {
        return "230px";
      } else if (this.$vuetify.breakpoint.md) {
        return "220px";
      } else if (this.$vuetify.breakpoint.smAndDown) {
        return this.$vuetify.breakpoint.xs ? "140px" : "240px";
      } else {
        return "300px";
      }
    },
  },
  data: () => ({
    routerOptions: [
      { title: "Inicio", icon: "mdi-home", to: "/" },
      { title: "Metodologia", icon: "mdi-help-box", to: "/metodologia" },
      { title: "Contato", icon: "mdi-email", to: "/contact" },
      { title: "Sobre", icon: "mdi-help-box", to: "/sobre" },
    ],
    drawer: false,
    socialMediaOptions: [
      {
        title: "Facebook",
        color: "#0288D1",
        icon: "mdi-facebook",
        to: "https://www.facebook.com/alexsandrojunior.bazzo.798",
      },
      {
        title: "Whatsapp",
        color: "#388E3C",
        icon: "mdi-whatsapp",
        to: "https://api.whatsapp.com/send?phone=5545999514501",
      },
      {
        title: "Instagram",
        color: "#E4405F",
        icon: "mdi-instagram",
        to: "https://www.instagram.com/bazzo_alex/",
      },
      {
        title: "Linkedin",
        color: "#0288D1",
        icon: "mdi-linkedin",
        to: "https://www.linkedin.com/in/bazzoalex/",
      },
      {
        title: "Twitter",
        color: "#0288D1",
        icon: "mdi-twitter",
        to: "https://twitter.com/AlxBazzo152888",
      },
    ],
  }),
  methods: {
    redirectToSocialMedia(route) {
      try {
        window.open(route, "_blank").focus();
      } catch (e) {
        console.error(e);
      }
    },
    openMail() {
      try {
        window.location.href = `mailto:mentor@alexbazzo.com.br `;
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style lang="css" scoped>
.boxed {
  margin: 0px 80px !important;
  padding: 0px;
  @media (min-width: 1904px) {
    margin: 0px 200px !important;
    padding: 0px;
  }
}

.img-card {
  width: 100% !important;
  align-items: start !important;
  align-content: start !important;
  justify-content: start !important;
}

.text-design {
  text-decoration: none;
}
.text-design:hover {
  cursor: pointer !important;
  transition: 0.5s;
  color: #05423c !important;
}
</style>

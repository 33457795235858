<template>
  <v-app theme="dark">
    <appBar />
    <v-main :class="'theme-color'">
      <router-view />
    </v-main>

    <!-- <v-row class="theme-color">
      <v-col cols="4" class="d-flex flex-md-column align-center">
        <v-img src="@/assets/logo.png" width="250"></v-img>
        <router-link
          v-for="(item, index) in routerOptions"
          :key="index"
          :to="item.to"
          class="white--text mt-3"
          >{{ item.title }}</router-link
        >
      </v-col>
      <v-col cols="4"> </v-col>
      <v-col cols="4"> </v-col>
    </v-row> -->
    <v-footer class="theme-color d-flex justify-center" dark padless>
      <v-card flat tile class="theme-color">
        <v-card-text class="white--text d-flex justify-space-around">
          <router-link
            v-for="(item, index) in routerOptions"
            :key="index"
            :to="item.to"
            class="white--text text-subtitle-1 text-decoration-none text-uppercase font-weight-bold footer-text"
          >
            {{ item.title }}
          </router-link>
        </v-card-text>
        <v-card-text class="white--text font-weight-black">
          {{ new Date().getFullYear() }} -
          <strong class="font-weight-black"
            >BAZZO DESENVOLVIMENTO EMPRESARIAL LTDA</strong
          >
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>
<script>
import appBar from "./Defaults/appBar.vue";
export default {
  name: "app",
  components: {
    appBar,
  },
  data: () => ({
    novo: false,
    routerOptions: [
      { title: "Inicio", icon: "mdi-home", to: "/" },
      { title: "Metodologia", icon: "mdi-help-box", to: "/metodologia" },
      { title: "Contato", icon: "mdi-email", to: "/contact" },
      { title: "Sobre", icon: "mdi-help-box", to: "/sobre" },
    ],
  }),
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
.theme-color {
  background-color: #101010 !important;
}

.row-height {
  min-height: 30vh !important;
}
.footer-text:hover {
  color: #42b983 !important;
}
</style>

import Vue from "vue";
import Toast from "vue-toastification";

import "vue-toastification/dist/index.css";

const options = {
    timeout: 3000,
};

Vue.use(Toast, options);


// USE TYPES:
// this.$toast("Default toast");
// this.$toast.info("Info toast");
// this.$toast.success("Success toast");
// this.$toast.error("Error toast");
// this.$toast.warning("Warning toast");
// this.$toast("Also a success toast", {
//     type: TYPE.SUCCESS  // or "success", "error", "default", "info" and "warning"
// });
